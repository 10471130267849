<template>
  <div id="more">
    <top :name="text" />
    <van-tabs v-model="activeName" animated swipeable @click="onclick">
      <van-tab title="商城订单" name="all">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;

                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="待支付" name="010">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="待发货" name="011">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }}</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }}
                </p>
              </div>
            </div>
          </div>
        </van-list></van-tab
      >
      <van-tab title="待收货" name="012">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>

      <van-tab title="已完成" name="013">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>

      <van-tab title="已取消" name="014">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list></van-tab
      >
      <van-tab title="退款/售后" name="200">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in position_data"
            :key="index"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                text-align: right;
              "
              :class="[
                item.state == '待支付' ? 'orange' : '',
                item.state == '待发货' ? 'blue' : '',
                item.state == '待收货' ? 'sky' : '',
                item.state == '交易完成' ? 'green' : '',
                item.state == '已取消' ? 'skycolor' : '',
              ]"
            >
              {{ item.state }}
            </p>
            <div class="shopping" @click="dingdan(item.orderid)">
              <div class="a">
                <img class="imghhh" :src="item.commodity_img" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="font-size: 0.26rem">单价：￥{{ item.one_price }} + 0积分</p>
                  <p>×{{ item.skunum }}</p>
                </div>
                <p style="color: red; font-size: 0.26rem">
                  总价：￥{{ item.total_price }} + 0积分
                </p>
              </div>
            </div>
          </div>
        </van-list></van-tab
      >
    </van-tabs>
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";

export default {
  data() {
    return {
      text: "商城订单",
      activeName: "",
      page: 1,
      position_data: {},
      // 滚动参数
      ischeck: false,
      loading: false,
      finished: false,
      // 切换防止滚动加载
      tabchange: false,
      // 滚动参数 End
      name: "",
      title_type: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "personalcenter" || from.name == "cart") {
        vm.activeName = vm.$route.query.id;
        // 个人中心进入
        vm.https(vm.activeName);
      }
      if (from.name == "morenext") {
        // 订单详情返回时 page初始化为1 标签映射页面回显
        (vm.page = 1),
          (vm.activeName = sessionStorage.getItem("dingdan_activename"));
        vm.$https(
          "mall/orderList",
          "phone=" +
            sessionStorage.getItem("user_phone") +
            "&id=" +
            sessionStorage.getItem("user_id") +
            "&page=" +
            vm.page +
            "&state=" +
            sessionStorage.getItem("dingdan_activename"),
          "post",
          "false"
        ).then((res) => {
          vm.position_data = res.data.data;
        });
      }
      // console.log(to,from)
    });
  },
  components: {
    top,
  },
  methods: {
    // 测试订单详情
    dingdan(orderid) {
      this.$https(
        "mall/orderDetails",
        "phone=" +
          sessionStorage.getItem("user_phone") +
          "&orderid=" +
          orderid +
          "&id=" +
          sessionStorage.getItem("user_id"),
        "post"
      ).then((res) => {
        if (res.data.code == "000000") {
          console.log(JSON.stringify(res.data.data));
          this.$router.push({
            name: "morenext",
            params: {
              success_data: JSON.stringify(res.data.data),
            },
          });
        }
      });
    },
    // 切换标签触发，更改状态码传输给后端
    onclick(e) {
      (this.page = 1), (this.finished = false), (this.tabchange = true);
      this.loading = true;
      this.name = e;
      // 记录点击的标签 订单详情返回时用到
      sessionStorage.setItem("dingdan_activename", this.activeName);
      // 切换标签时 page初始化
      this.https(e);
    },

    //滚动条触发事件
    onLoad() {
      this.loading = true;
      this.page = this.page + 1;
      console.log("触发", this.page);
      console.log(this.tabchange, "是否在家");
      setTimeout(() => {
        if (this.tabchange == false) {
          this.$https(
            "mall/orderList",
            "phone=" +
              sessionStorage.getItem("user_phone") +
              "&id=" +
              sessionStorage.getItem("user_id") +
              "&page=" +
              this.page +
              "&state=" +
              this.activeName,
            "post"
          )
            .then((res) => {
              if (res.data.data.length >= 1) {
                res.data.data.forEach((item, index) => {
                  this.position_data.push(item);
                });
                this.loading = false;
              }
              if (res.data.data.length < 10) {
                this.finished = true;
              }
            })
            .catch((e) => {
              console.log("错误报文", e);
            });
        }
        this.tabchange = false;
      }, 1000);
    },
    https(e) {
      // 2a7dc6d8395147f7956277e1ed4b3581
      this.$https(
        "mall/orderList",
        "phone=" +
          sessionStorage.getItem("user_phone") +
          "&id=" +
          sessionStorage.getItem("user_id") +
          "&page=" +
          this.page +
          "&state=" +
          e,
        "post"
      ).then((res) => {
        res.data.code == "000000"
          ? ((this.position_data = res.data.data), (this.tabchange = false))
          : this.$toast.fail("请重新进入");
        this.loading = false;
        if (res.data.data.length < 10) {
          this.finished = true;
        }
      });
    },
  },
  mounted() {
    // 记录点击的标签 订单详情返回时用到
    sessionStorage.setItem("dingdan_activename", this.activeName);
    // 渲染订单列表
  },
};
</script>

<style scoped>
/* 状态码class */
.sky {
  color: red;
}
.green {
  color: green;
}
.skycolor {
  color: #c3c3c3;
}
.orange {
  color: orange;
}
.blue {
  color: skyblue;
}
/* 状态码class End */
#more {
  height: auto;
  background: #f8f8f8;
}
.shopping {
  display: flex;
  overflow-y: scroll;
}
.a {
  flex-basis: 41%;
  display: flex;
  align-items: center;
  height: 2rem;
  border: 0.01rem solid rgb(204, 204, 204);
  border-radius: 0.14rem;
  flex: 1;
  margin-top: 0.2rem;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.imghhh {
  width: 80%;
  height: auto;
}
.shopping:nth-child(2) {
  font-size: 0.3rem;
}
.zuoyou {
  display: flex;
  font-size: 0.3rem;
  align-content: center;
  justify-content: space-between;
}
.bn {
  margin: 0.2rem 0 0.2rem 0;
}
</style>
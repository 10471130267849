var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"more"}},[_c('top',{attrs:{"name":_vm.text}}),_c('van-tabs',{attrs:{"animated":"","swipeable":""},on:{"click":_vm.onclick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('van-tab',{attrs:{"title":"商城订单","name":"all"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"待支付","name":"010"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"待发货","name":"011"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price))]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"待收货","name":"012"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"已完成","name":"013"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"已取消","name":"014"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1),_c('van-tab',{attrs:{"title":"退款/售后","name":"200"}},[_c('van-list',{attrs:{"immediate-check":_vm.ischeck,"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.position_data),function(item,index){return _c('div',{key:index,staticStyle:{"width":"96%","background":"white","padding":"0.1rem","height":"auto","margin":"0.1rem auto 0","border-radius":"0.2rem"}},[_c('p',{class:[
              item.state == '待支付' ? 'orange' : '',
              item.state == '待发货' ? 'blue' : '',
              item.state == '待收货' ? 'sky' : '',
              item.state == '交易完成' ? 'green' : '',
              item.state == '已取消' ? 'skycolor' : '' ],staticStyle:{"font-size":"0.26rem","margin":"0 0 0 0.2rem","text-align":"right"}},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"shopping",on:{"click":function($event){return _vm.dingdan(item.orderid)}}},[_c('div',{staticClass:"a"},[_c('img',{staticClass:"imghhh",attrs:{"src":item.commodity_img,"alt":""}})]),_c('div',{staticStyle:{"position":"relative","flex":"1.6"}},[_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.24rem","text-overflow":"ellipsis","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(item.commodity_name)+" ")]),_c('p',{staticClass:"bn",staticStyle:{"font-size":"0.2rem","color":"gray"}},[_vm._v(" "+_vm._s(item.commodity_sku)+" ")]),_c('div',{staticClass:"zuoyou"},[_c('p',{staticStyle:{"font-size":"0.26rem"}},[_vm._v("单价：￥"+_vm._s(item.one_price)+" + 0积分")]),_c('p',[_vm._v("×"+_vm._s(item.skunum))])]),_c('p',{staticStyle:{"color":"red","font-size":"0.26rem"}},[_vm._v(" 总价：￥"+_vm._s(item.total_price)+" + 0积分 ")])])])])}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }